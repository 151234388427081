<div class="container-fluid my-5">
    <div class="row gx-5">
      <div class="col-12 col-md-5">
        <div class="text-start mb-4">
          <h5 class="subtitle">{{heading}}</h5>
          <h2>{{ title }}</h2>
          <p>{{ subtitle }}</p>
          <div class="button" (click)="scrollToIntroduction()">
            {{ buttonText }} 
            @if(buttonIcon){
            <img src="{{buttonIcon}}" alt="forward">
            }
          </div>          
        </div>
      </div>

      <div class="col-12 col-md-7">
        <div class="row g-4">
          @for (process of processes; track process) {
            <div class="col-12 col-md-6">
              <div class="text-start">
                <img [src]="cmsUrl + process.processLogo.url" [alt]="process.processLogo.alternativeText" class="mb-3">
                <h4 class="title">{{process.title}}</h4>
                <div [innerHTML]="process.safeHtml"></div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>