<div class="row">
  <div class="col-6">
    @if(image){
    <img class="image" src="{{image}}" alt="Mobile Phone">
    }
  </div>
  <div class="col-6">
    <h2 class="subtitle">
      {{heading}}
    </h2> <br>
<h1 class="title">
  {{title}}
</h1>
<h3 class="text">{{text}}</h3>
<div class="ticks">
  <span class="text-tick">
    @if(tick){
    <img class="tick" src="{{tick}}" alt="tick">
    }
  <h3>{{tick1}}</h3></span><br>
  <span class="text-tick">
    @if(tick){
    <img class="tick" src="{{tick}}" alt="tick">
    }
    <h3>{{tick2}}</h3></span><br>
  <span class="text-tick">
    @if(tick){
    <img class="tick" src="{{tick}}" alt="tick">
    }
  <h3>{{tick3}}</h3></span>
  </div>
  <div class="button" (click)="scrollToIntroduction()">{{buttonText}}</div>
  </div>
</div>
