<div class="cards">
  @for(card of cards; track $index){
  <div class="card">
    <div class="card-content">
      <img [src]="card.image" [alt]="card.title">
      <h2 class="card-title">
        {{ card.title }}
      </h2>
      <h3 class="card-text">
        {{ card.text }}
      </h3>
    </div>
  </div>
}
</div>
