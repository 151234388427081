<div class="cards">
  <div class="card" *ngFor="let card of cards">
    <div class="card-content">
      <img [src]="card.image" [alt]="card.title">
      <span class="card-title">
        {{ card.title }}
      </span>
      <p class="card-text">
        {{ card.text }}
      </p>
    </div>
  </div>
</div>
