import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, inject, OnInit, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { CmsService } from '../../services/cms.service';
import { environment } from '../../../environments/environment';
import { EmailService } from '../../services/email.service';

interface Option {
  value: string;
  imgSrc?: string;
  altText?: string;
  label: string;
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    CommonModule
  ],
})
export class FormComponent implements OnInit {
  currentStep = signal(1);
  step1Form!: FormGroup;
  step2Form!: FormGroup;
  step3Form!: FormGroup;
  step4Form!: FormGroup;
  step5Form!: FormGroup;
  step6Form!: FormGroup;
  step7Form!: FormGroup;
  step8Form!: FormGroup;
  formSubmitted = signal(false);
  formError = signal<string | null>(null);
  formSuccess = signal<string | null>(null);
  osOptions: Option[] = [];
  phaseOptions: Option[] = [];
  loginOptions: Option[] = [];
  languageOptions: Option[] = [];
  designOptions: Option[] = [];
  monetizationOptions: Option[] = [];
  startOptions: Option[] = [];
  cmsUrl = environment.CMS_URL;
  nextButtonText : string = '';
  backButton : string = '';
  submitButtonText : string = '';
  content : any;
  emailSubject : string = '';
  emailText : string = ''; 

  fb = inject(FormBuilder);
  http = inject(HttpClient);
  cms = inject(CmsService);
  snackBar = inject(MatSnackBar);
  email = inject(EmailService);

  ngOnInit(): void {
    this.initializeForms();
    this.getForms();
    this.getContent();
  }

  getForms(){
    let field = 'imgSrc';
    let locale = 'en';
    let osForm = 'form-os-options';
    let phaseForm = 'form-phase-options';
    let loginForm = 'form-login-options';
    let languageForm = 'form-language-options';
    let designForm = 'form-design-options';
    let monetizationForm = 'form-monetization-options';
    let startForm = 'form-start-options';

    this.cms.getForms(osForm, field, locale).subscribe((res:any)=> this.osOptions = this.mapOptions(res.data));
    this.cms.getForms(phaseForm, field, locale).subscribe((res:any)=> this.phaseOptions = this.mapOptions(res.data));
    this.cms.getForms(loginForm, field, locale).subscribe((res:any)=> this.loginOptions = this.mapOptions(res.data));
    this.cms.getForms(languageForm, field, locale).subscribe((res:any)=> this.languageOptions = this.mapOptions(res.data));
    this.cms.getForms(designForm, field, locale).subscribe((res:any)=> this.designOptions = this.mapOptions(res.data));
    this.cms.getForms(monetizationForm, field, locale).subscribe((res:any)=> this.monetizationOptions = this.mapOptions(res.data));
    this.cms.getForms(startForm, field, locale).subscribe((res:any)=> this.startOptions = this.mapOptions(res.data));
  }

  getContent(){
    let component = 'form';
    let fields = ['*'];
    let locale = 'en';
  
    this.cms.getContent(component, fields, locale).subscribe({
      next: (res: any) => {
        if (res?.data?.form) {
          this.content = res.data.form;
          this.backButton = this.cmsUrl + this.content.backButton?.url;
          this.nextButtonText = this.content.nextButtonText;
          this.submitButtonText = this.content.submitButtonText;
          this.emailSubject = this.content.emailSubject;
          this.emailText = this.content.emailText;
  
        } else {
          console.warn('Form data is missing in the CMS response:', res);
        }
      },
      error: (error) => {
        console.error('Error fetching content from CMS:', error);
      }
    });
  }

  private mapOptions(data: any[]): Option[] {
    return data.map(item => ({
      value: item.value,
      imgSrc:this.cmsUrl + item.imgSrc?.url,
      altText: item.altText,
      label: item.label,
    }));
  }

  private initializeForms(): void {
    this.step1Form = this.fb.group({
      operatingSystem: ['', Validators.required],
    });

    this.step2Form = this.fb.group({
      projectPhase: ['', Validators.required],
    });

    this.step3Form = this.fb.group({
      userLogin: ['', Validators.required],
    });

    this.step4Form = this.fb.group({
      languagesSupported: ['', Validators.required],
    });

    this.step5Form = this.fb.group({
      designRequirement: ['', Validators.required],
    });

    this.step6Form = this.fb.group({
      appMonetization: ['', Validators.required],
    });

    this.step7Form = this.fb.group({
      developmentStart: ['', Validators.required],
    });

    this.step8Form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      company: ['', Validators.required],
      message: [''],
    });
  }

  goToNextStep(): void {
    if (this.isCurrentFormValid()) {
      this.currentStep.set(this.currentStep() + 1);
    } else {
      this.markCurrentFormGroupTouched();
    }
  }

  goToPreviousStep(): void {
    if (this.currentStep() > 1) {
      this.currentStep.set(this.currentStep() - 1);
    }
  }

  private isCurrentFormValid(): boolean {
    const currentForm = this.getCurrentForm();
    return currentForm ? currentForm.valid : false;
  }

  private getCurrentForm(): FormGroup | null {
    switch (this.currentStep()) {
      case 1:
        return this.step1Form;
      case 2:
        return this.step2Form;
      case 3:
        return this.step3Form;
      case 4:
        return this.step4Form;
      case 5:
        return this.step5Form;
      case 6:
        return this.step6Form;
      case 7:
        return this.step7Form;
      case 8:
        return this.step8Form;
      default:
        return null;
    }
  }

  private markCurrentFormGroupTouched(): void {
    const currentForm = this.getCurrentForm();
    if (currentForm) {
      Object.values(currentForm.controls).forEach(control => {
        control.markAsTouched();
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(c => c.markAsTouched());
        }
      });
    }
  }

  async onSubmit(): Promise<void> {
    if (this.allFormsValid()) {
      try {
        this.formSubmitted.set(true);
        const formData = this.prepareFormData();
        const response = await this.submitForm(formData);
        this.handleSuccess(response);
        await this.email.sendConfirmationEmail(formData.email,this.emailSubject,this.emailText).toPromise();
      } catch (error) {
        console.error('Error submitting form:', error);
        this.handleError(error);
      } finally {
        this.formSubmitted.set(false);
      }
    } else {
      this.markAllFormsAsTouched();
    }
  }
  

  private handleSuccess(response: any): void {
    this.formSuccess.set('Your form has been submitted successfully!');
    this.openSnackBar('Form submitted successfully!', 'Close');
    this.resetForms();

    setTimeout(() => {
      this.formSuccess.set(null);
    }, 2000);
  }
  
  private handleError(error: any): void {
    this.formError.set('An error occurred while submitting the form. Please try again.');
    this.openSnackBar('Failed to submit form. Please try again.', 'Close');

    setTimeout(() => {
      this.formSuccess.set(null);
    }, 2000);
  }
  
  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top', 
    });
  }

  private resetForms(): void {
    this.initializeForms();
    this.currentStep.set(1);
  }

  private allFormsValid(): boolean {
    return [
      this.step1Form,
      this.step2Form,
      this.step3Form,
      this.step4Form,
      this.step5Form,
      this.step6Form,
      this.step7Form,
      this.step8Form
    ].every(form => form.valid);
  }

  private prepareFormData(): any {
    return {
      operatingSystem: this.step1Form.value.operatingSystem,
      projectPhase: this.step2Form.value.projectPhase,
      userLogin: this.step3Form.value.userLogin,
      languagesSupported: this.step4Form.value.languagesSupported,
      designRequirement: this.step5Form.value.designRequirement,
      appMonetization: this.step6Form.value.appMonetization,
      developmentStart: this.step7Form.value.developmentStart,
      name: this.step8Form.value.name,
      email: this.step8Form.value.email,
      company: this.step8Form.value.company,
      message: this.step8Form.value.message,
    };
  }

  private async submitForm(data: any): Promise<any> {
    return await this.cms.submitFormData(data).toPromise();
  }

  private markAllFormsAsTouched(): void {
    [
      this.step1Form,
      this.step2Form,
      this.step3Form,
      this.step4Form,
      this.step5Form,
      this.step6Form,
      this.step7Form,
      this.step8Form
    ].forEach(form => {
      Object.values(form.controls).forEach(control => {
        control.markAsTouched();
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(c => c.markAsTouched());
        }
      });
    });
  }

  getControlError(formGroup: FormGroup, controlName: string): string {
    const control = formGroup.get(controlName);
    if (control?.errors && control.touched) {
      if (control.errors['required']) return `${controlName} is required`;
      if (control.errors['email']) return 'Invalid email format';
      if (control.errors['pattern']) return 'Invalid format';
      if (control.errors['minlength']) return `Minimum length is ${control.errors['minlength'].requiredLength}`;
      if (control.errors['max']) return `Maximum value exceeded`;
    }
    return '';
  }
}
