<div class="row">
  <div class="col-6">
    @if(image){
    <img class="image" src="{{image}}" alt="Mobile Phone">
    }
  </div>
  <div class="col-6">
    <span class="subtitle">
      {{heading}}
    </span> <br>
<span class="title">
  {{title}}
</span>
<p class="text">{{text}}</p>
<div class="ticks">
  <span class="text-tick">
    @if(tick){
    <img class="tick" src="{{tick}}" alt="tick">
    }
  <p>{{tick1}}</p></span><br>
  <span class="text-tick">
    @if(tick){
    <img class="tick" src="{{tick}}" alt="tick">
    }
    <p>{{tick2}}</p></span><br>
  <span class="text-tick">
    @if(tick){
    <img class="tick" src="{{tick}}" alt="tick">
    }
  <p>{{tick3}}</p></span>
  </div>
  <div class="button" (click)="scrollToIntroduction()">{{buttonText}}</div>
  </div>
</div>
