import { Component, inject, OnInit } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  language: string = 'en';
  menu: any[] = [];
  logoUrl: string = '';
  activeMenu: string = 'HOME';

  cms = inject(CmsService);
  private router = inject(Router);

  ngOnInit(): void {
    this.getContent();
  }

  getContent() {
    let component = 'header';
    let locale = 'en';
    let fields = ['logo', 'menus'];

    this.cms.getContent(component, fields, locale).subscribe({
      next: (response: any) => {
        const header = response.data.header;
        this.logoUrl = environment.CMS_URL + header.logo.formats.small.url;
        this.menu = header.menus.map((menu: any) => ({
          title: menu.menuTitle,
          redirectTo: menu.redirectTo
        }));
      },
      error: (error) => {
        console.error('Error fetching introduction content:', error);
      }
    });
  }

  isActive(menuTitle: string): boolean {
    return this.activeMenu === menuTitle;
  }

  navigateTo(item: any) {
    if (typeof item === 'string' && item === '') {
      this.activeMenu = 'HOME';
      this.router.navigate(['/']); 
    } else {
      this.activeMenu = item.title;
      if (item.redirectTo) {
        this.router.navigate([item.redirectTo]);
      }
    }
  }
  
}
