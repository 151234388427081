import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  http = inject(HttpClient);

  constructor() { }

  getContent(component: string, fields: string[], locale: string) {
    const populateFields = fields.map(field => `populate[${component}][populate]=${field}`).join('&');
    return this.http.get(`${environment.CMS_URL}/api/home-page?${populateFields}&locale=${locale}`);
  }

  getNestedContent(component: string, nestedFields: string[], populateField: string, locale: string) {
    let query = `populate[${component}]`;
    nestedFields.forEach(field => {
      query += `[populate][${field}]`;
    });
    query += `[populate]=${populateField}`;
    return this.http.get(`${environment.CMS_URL}/api/home-page?${query}&locale=${locale}`);
  }

  getFooterContent(component: string, nestedFields: string[], populateField: string, locale: string) {
    let query = nestedFields.map(field => {
        if (field === 'socials') {
            return `populate[${component}][populate][${field}][populate][social]=${populateField}`;
        } else {
            return `populate[${component}][populate][${field}]=${populateField}`;
        }
    }).join('&');
    
    return this.http.get(`${environment.CMS_URL}/api/home-page?${query}&locale=${locale}`);
  }

  getCards(locale:string){
    return this.http.get(`${environment.CMS_URL}/api/services-cards?populate=*&locale=${locale}`);
  }

  getForms(page:string,field:string, locale:string){
    return this.http.get(`${environment.CMS_URL}/api/${page}?populate=${field}&locale=${locale}`);
  }

  getSeoContent(locale: string): Observable<any> {
    const query = `
      populate[seo][populate][seoGoogle]=* &
      populate[seo][populate][metaSocial][populate][image]=* &
      populate[seo][populate][metaImage]=*
    `.replace(/\s+/g, '');
    return this.http.get(`${environment.CMS_URL}/api/home-page?${query}&locale=${locale}`);
  }
  

  submitFormData(data: any): Observable<any> {
    const url = `${environment.CMS_URL}/api/form-submissions`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, { data }, { headers });
  }
}
