import { Component, inject, OnInit } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { FormComponent } from '../form/form.component';
import { slideInRight } from '../../animations/animations';

interface IntroductionData {
  heading: string;
  title: string;
  titleAnimation1: string;
  titleAnimation2: string;
  subtitle: string;
}

@Component({
  selector: 'app-introduction',
  standalone: true,
  imports: [FormComponent],
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss'],
  animations:[slideInRight] 
})
export class IntroductionComponent implements OnInit {
  phrases: string[] = [];
  currentText: string = '';
  currentPhraseIndex: number = 0;
  currentLetterIndex: number = 0;
  isDeleting: boolean = false;
  typingSpeed: number = 150;
  deletingSpeed: number = 75;
  pauseBetweenPhrases: number = 2000;
  pauseBeforeTyping: number = 500;

  heading: string = '';
  title: string = '';
  subtitle: string = '';

  cms = inject(CmsService);

  ngOnInit() {
    this.getContent();
  }

  typingLoop() {
    const currentPhrase = this.phrases[this.currentPhraseIndex];
    if (this.isDeleting) {
      if (this.currentLetterIndex > 0) {
        this.currentLetterIndex--;
        this.currentText = currentPhrase.substring(0, this.currentLetterIndex);
        setTimeout(() => this.typingLoop(), this.deletingSpeed);
      } else {
        this.isDeleting = false;
        this.currentPhraseIndex = (this.currentPhraseIndex + 1) % this.phrases.length;
        setTimeout(() => this.typingLoop(), this.pauseBeforeTyping);
      }
    } else {
      if (this.currentLetterIndex < currentPhrase.length) {
        this.currentLetterIndex++;
        this.currentText = currentPhrase.substring(0, this.currentLetterIndex);
        setTimeout(() => this.typingLoop(), this.typingSpeed);
      } else {
        this.currentText = currentPhrase;
        this.isDeleting = true;
        setTimeout(() => this.typingLoop(), this.pauseBetweenPhrases);
      }
    }
  }

  getContent() {
    let component = 'introduction';
    let locale = 'en';
    let fields = [''];

    this.cms.getContent(component, fields, locale).subscribe({
      next: (response: any) => {
        const introData: IntroductionData = response.data.introduction;
        this.phrases = [introData.titleAnimation1, introData.titleAnimation2];
        
        this.heading = introData.heading;
        this.title = introData.title;
        this.subtitle = introData.subtitle;

        this.currentText = this.phrases[this.currentPhraseIndex];
        this.typingLoop();
      },
      error: (error) => {
        console.error('Error fetching introduction content:', error);
      }
    });
  }
}