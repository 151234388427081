import { Component, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CmsService } from '../../services/cms.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  text: string = '';
  copyright: string = '';
  socials : any[] = [];
  logo : string = '';
  cmsUrl = environment.CMS_URL;

  cms = inject(CmsService);
  router = inject(Router);

  ngOnInit(): void {
    this.getContent();
  }

  getContent(){
    let component = 'footer';
    let fields = ['logo', 'socials'];
    let populateField = '*';
    let locale = 'en';

    this.cms.getFooterContent(component, fields, populateField, locale).subscribe((res: any) => {
      const content = res.data.footer;
      this.text = content.text;
      this.copyright = content.copyright;
      this.logo = this.cmsUrl + content.logo.formats.small.url;
      this.socials = content.socials;
    });
}


  navigateTo(url: string) {
    if (url) {
      window.open(url, '_blank'); 
    }
  }
}
