<div class="row">
  <div class="logo col-8">
    @if(logoUrl){
    <img class="logo-img" [src]="logoUrl" alt="Logo">
    }
  </div>
  <div class="menu-items d-flex align-items-center justify-content-center gap-4 col-4">
    @for(item of menu; track item){
    <span 
      class="menu-item" 
      [class.active]="isActive(item.title)" 
      (click)="navigateTo(item)">
      {{ item.title }}
    </span>
  }
  </div>
</div>
