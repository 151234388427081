import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private http = inject(HttpClient);
  constructor() { }

  sendEmail(data?:any):Observable<any>{
      return this.http.post(`${environment.CMS_URL}/api/email`, data);
  }

  sendConfirmationEmail(email: string, subject : string, text:string) {
    const data = {
      to: email,
      from: 'iSolve <app@i-solve.info>', 
      subject: subject,
      text: text,
      html: `<p>${text}</p>`
    };
    return this.sendEmail(data);
  }
}
