import { trigger, style, animate, transition } from '@angular/animations';

export const slideInRight = trigger('slideInRight', [
  transition(':enter', [
    style({ transform: 'translateX(100%)', opacity: 0 }),
    animate('600ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(0)', opacity: 1 }))
  ])
]);

export const growFadeIn = trigger('growFadeIn', [
  transition(':enter', [
    style({ transform: 'scale(0.5)', opacity: 0 }),
    animate(
      '800ms 500ms cubic-bezier(0.25, 0.8, 0.25, 1)',
      style({ transform: 'scale(1)', opacity: 1 })
    )
  ])
]);

export const slideInFromTop = (duration: string) => trigger('slideInFromTop', [
  transition(':enter', [
    style({ transform: 'translateY(-100px)', opacity: 0 }),
    animate(duration, style({ transform: 'translateY(0)', opacity: 1 }))
  ])
]);


