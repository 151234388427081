<div class="heading">
  <h2 class="subtitle">{{ heading }}</h2>
</div>
<div class="row">
  <div class="col-md-6" @slideInRight>
    <h1 class="text">
      {{ title }}
      <h1 class="typing-text">{{ currentText }}</h1>
    </h1>
    <br>
    <h3 class="subtext">{{ subtitle }}</h3>
  </div>
  <div class="col-md-6">
    <app-form></app-form>
  </div>
</div>
