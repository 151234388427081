<div class="heading">
  <span class="subtitle">{{ heading }}</span>
</div>
<div class="row">
  <div class="col-md-6" @slideInRight>
    <span class="text">
      {{ title }}
      <span class="typing-text">{{ currentText }}</span>
    </span>
    <br>
    <span class="subtext">{{ subtitle }}</span>
  </div>
  <div class="col-md-6">
    <app-form></app-form>
  </div>
</div>
