import { Component, inject } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';



interface ProcessBlock {
  type: 'paragraph' | 'list';
  format?: 'ordered' | 'unordered'; 
  children: ProcessChild[];
}

interface ProcessChild {
  text: string;
  bold?: boolean;
  children:[];
}

interface Process {
  text: ProcessBlock[];
}

@Component({
  selector: 'app-process',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss'],
})

export class ProcessComponent {
  heading = '';
  title = '';
  subtitle = '';
  buttonText = '';
  buttonIcon = '';
  processes: any[] = [];
  cmsUrl = environment.CMS_URL;

  cms = inject(CmsService);
  private sanitizer = inject(DomSanitizer);

  ngOnInit(): void {
    this.getContent();
  }

  getContent() {
    const component = 'process';
    const fields = ['processes'];
    const populateField = 'processLogo';
    const locale = 'en';

    this.cms
      .getNestedContent(component, fields, populateField, locale)
      .subscribe((res: any) => {
        const content = res.data.process;
        if (content) {
          this.heading = content.heading;
          this.title = content.title;
          this.subtitle = content.subtitle;
          this.buttonText = content.buttonText;
          // this.buttonIcon = this.cmsUrl + content.buttonIcon.url;
          this.processes = content.processes.map((process: any) => ({
            ...process,
            safeHtml: this.getSafeHtml(process.text)
          }));
        }
      });
  }


  scrollToIntroduction() {
    const introductionElement = document.querySelector('app-introduction');
    if (introductionElement) {
      introductionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getSafeHtml(text: ProcessBlock[]): SafeHtml {
    let html = '';
    for (const block of text) {
      if (block.type === 'paragraph') {
        html += `<p>${this.getInnerHtml(block.children)}</p>`;
      } else if (block.type === 'list') {
        const listType = block.format === 'ordered' ? 'ol' : 'ul';
        html += `<${listType}>${this.getListItems(block.children)}</${listType}>`;
      }
    }
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  
  private getInnerHtml(children: ProcessChild[]): string {
    return children.map(child => {
      if (child.bold) {
        return `<strong>${child.text}</strong>`;
      }
      return child.text;
    }).join('');
  }
  
  private getListItems(items: ProcessChild[]): string {
    return items.map(item => `<li>${this.getInnerHtml(item.children)}</li>`).join('');
  }
}