<div class="footer">
  <div class="footer-content">
    <div class="logo">
      @if(logo){
      <img class="logo-img" src="{{logo}}" alt="iSolve Logo">
      }
    </div>
    <div class="text">
      <p>{{text}}</p>  
    </div>
    
    <div class="social-icons">
      @for(social of socials; track social){
     <span>
      <img [src]="cmsUrl + social?.social?.url" [alt]="social?.social?.alternativeText || 'social icon'" (click)="navigateTo(social.socialUrl)">
    </span>
    }
    </div>
  
    <hr class="footer-line">
    <div class="footer-bottom">
      <div class="copyright">
        <span>{{copyright}}</span>
      </div>
    </div>
  </div>
</div>
