import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { CmsService } from './cms.service';
import { catchError, EMPTY, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private readonly meta = inject(Meta);
  private readonly titleService = inject(Title);
  private readonly document = inject(DOCUMENT);
  private readonly renderer = inject(RendererFactory2).createRenderer(null, null);
  private readonly cmsService = inject(CmsService);

  public initializeSeo(): void {
    this.getAndUpdateSeo().pipe(
      catchError(error => {
        console.error('Error initializing SEO:', error);
        return EMPTY;
      })
    ).subscribe();
  }

  private getAndUpdateSeo(): Observable<any> {
    const locale = 'en'; 
    return this.cmsService.getSeoContent(locale).pipe(
      tap(response => {
        if (response?.data?.seo) {
          const seoData = response.data.seo;
          this.updateAllSeoTags(seoData);
        } else {
          console.warn('No SEO data found in response');
        }
      })
    );
  }

  private updateAllSeoTags(seoData: any): void {
    this.updateMetaTags(seoData);
    this.updateSocialTags(seoData?.metaSocial);
    this.updateGoogleTags(seoData?.seoGoogle);
    if (seoData?.structuredData) {
      this.updateStructuredData(seoData.structuredData);
    }
  }

  private updateMetaTags(data: any): void {
    if (!data) return;
    if (data.metaTitle) {
      this.titleService.setTitle(data.metaTitle);
    }
    const metaTags = {
      description: data.metaDescription,
      keywords: data.keywords,
      robots: data.metaRobots,
      viewport: data.metaViewport,
      canonical: data.canonicalURL
    };

    Object.entries(metaTags).forEach(([name, content]) => {
      if (content) {
        this.updateOrCreateMetaTag(name, content);
      }
    });
  }

  private updateSocialTags(socialData: any[]): void {
    if (!Array.isArray(socialData)) return;
    socialData.forEach(social => {
      if (!social?.socialNetwork) return;
      const network = social.socialNetwork.toLowerCase();
      const imageUrl = social.image?.formats?.thumbnail?.url || social.image?.url;
      const fullImageUrl = `${environment.CMS_URL}${imageUrl}`;
      const socialTags = {
        [`og:${network}:title`]: social.title,
        [`og:${network}:description`]: social.description,
        [`og:${network}:image`]: fullImageUrl
      };

      Object.entries(socialTags).forEach(([property, content]) => {
        if (content) {
          this.updateOrCreateMetaTag(property, content, true);
        }
      });
    });
  }

  private updateGoogleTags(googleData: any): void {
    if (!googleData) return;
    if (googleData.verificationToken) {
      this.updateOrCreateMetaTag('google-site-verification', googleData.verificationToken);
    }
    if (googleData.googleAnalyticsId) {
      this.setupGoogleAnalytics(googleData.googleAnalyticsId);
    }
  }

  private updateOrCreateMetaTag(
    name: string, 
    content: string, 
    isProperty: boolean = false
  ): void {
    if (!name || !content) return;
    try {
      const attrSelector = isProperty ? 'property' : 'name';
      const selector = `${attrSelector}="${name}"`;
      const metaDefinition = {
        [attrSelector]: name,
        content
      };
      const tag = this.meta.getTag(selector);
      if (tag) {
        this.meta.updateTag(metaDefinition);
      } else {
        this.meta.addTag(metaDefinition);
      }
    } catch (error) {
      console.error(`Error updating meta tag ${name}:`, error);
    }
  }

  private updateStructuredData(data: any): void {
    if (!data) return;
    try {
      const existingScript = this.document.querySelector('script[type="application/ld+json"]');
      if (existingScript) {
        existingScript.remove();
      }
      const scriptElement = this.renderer.createElement('script');
      this.renderer.setAttribute(scriptElement, 'type', 'application/ld+json');
      this.renderer.setProperty(scriptElement, 'text', JSON.stringify(data));
      this.renderer.appendChild(this.document.head, scriptElement);
    } catch (error) {
      console.error('Error updating structured data:', error);
    }
  }

  private setupGoogleAnalytics(gaId: string): void {
    try {
      const gaScript = this.renderer.createElement('script');
      this.renderer.setAttribute(gaScript, 'async', 'true');
      this.renderer.setAttribute(gaScript, 'src', `https://www.googletagmanager.com/gtag/js?id=${gaId}`);
      this.renderer.appendChild(this.document.head, gaScript);
      const configScript = this.renderer.createElement('script');
      const configContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gaId}', {
          'anonymize_ip': true,
          'cookie_flags': 'SameSite=None;Secure'
        });
      `;
      this.renderer.setProperty(configScript, 'text', configContent);
      this.renderer.appendChild(this.document.head, configScript);
    } catch (error) {
      console.error('Error setting up Google Analytics:', error);
    }
  }
}
