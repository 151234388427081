<div class="right-section-inner">

  <form (ngSubmit)="onSubmit()" [attr.aria-busy]="formSubmitted()">
    @if(formError()){
      <div class="message-container error-message" role="alert">
        {{ formError() }}
      </div>
    }

    @if(formSuccess()){
      <div class="message-container success-message" role="alert">
        {{ formSuccess() }}
      </div>
    }
    <div class="wrapper show-section">
      <!-- Step 1 -->
      @if (currentStep() === 1) {
        <section class="steps step-1" role="group" aria-label="Operating System Selection">
          <div class="steps-inner">
            <div class="main-heading">
              {{content?.osOptions?.title}}
            </div>

            <div id="step1">
              <div class="lightSpeedIn row" [formGroup]="step1Form">
                @for (option of osOptions; track option.value) {
                  <div class="col-md-4">
                    <div class="job-single">
                      <input
                        type="radio"
                        formControlName="operatingSystem"
                        [value]="option.value"
                        [id]="option.value"
                        [attr.aria-invalid]="step1Form.get('operatingSystem')?.invalid && step1Form.get('operatingSystem')?.touched">
                      <label [for]="option.value">
                        <img [src]="option.imgSrc" [alt]="option.altText">
                        {{ option.label }}
                      </label>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div class="next-prev pop">
              <button
                type="button"
                class="next"
                (click)="goToNextStep()"
                [disabled]="step1Form.invalid || formSubmitted()"
                aria-label="Go to next step">
                {{nextButtonText}}<i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </section>
      }

      <!-- Step 2 -->
      @if (currentStep() === 2) {
        <section class="steps step-2" role="group" aria-label="Project Phase">
          <div class="steps-inner">
            <div class="main-heading">
              {{content?.phaseOptions?.title}}
            </div>

            <div id="step2">
              <div class="lightSpeedIn row" [formGroup]="step2Form">
                @for (option of phaseOptions; track option.value) {
                  <div class="col-md-4">
                    <div class="job-single">
                      <input
                        type="radio"
                        formControlName="projectPhase"
                        [value]="option.value"
                        [id]="option.value"
                        [attr.aria-invalid]="step2Form.get('projectPhase')?.invalid && step2Form.get('projectPhase')?.touched">
                      <label [for]="option.value">
                        <img [src]="option.imgSrc" [alt]="option.altText">
                        {{ option.label }}
                      </label>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div class="next-prev pop">
              <button
                type="button"
                class="prev"
                (click)="goToPreviousStep()"
                [disabled]="formSubmitted()"
                aria-label="Go to previous step">
                @if(backButton){
                <img src="{{backButton}}" alt="Back">
                }
              </button>
              <button
                type="button"
                class="next"
                (click)="goToNextStep()"
                [disabled]="step2Form.invalid || formSubmitted()"
                aria-label="Go to next step">
                {{nextButtonText}}<i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </section>
      }
      <!-- Step 3 -->
      @if (currentStep() === 3) {
        <section class="steps step-3" role="group" aria-label="User Login">
          <div class="steps-inner">
            <div class="main-heading">
              {{content?.loginOptions?.title}}
            </div>

            <div id="step3">
              <div class="lightSpeedIn row" [formGroup]="step3Form">
                @for (option of loginOptions; track option.value) {
                  <div class="col-md-4">
                    <div class="job-single">
                      <input
                        type="radio"
                        formControlName="userLogin"
                        [value]="option.value"
                        [id]="option.value"
                        [attr.aria-invalid]="step3Form.get('userLogin')?.invalid && step3Form.get('userLogin')?.touched">
                      <label [for]="option.value">
                        <img [src]="option.imgSrc" [alt]="option.altText">
                        {{ option.label }}
                      </label>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div class="next-prev pop">
              <button
                type="button"
                class="prev"
                (click)="goToPreviousStep()"
                [disabled]="formSubmitted()"
                aria-label="Go to previous step">
                @if(backButton){
                  <img src="{{backButton}}" alt="Back">
                  }
              </button>
              <button
                type="button"
                class="next"
                (click)="goToNextStep()"
                [disabled]="step3Form.invalid || formSubmitted()"
                aria-label="Go to next step">
                {{nextButtonText}}<i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </section>
      }

      <!-- Step 4 -->
      @if (currentStep() === 4) {
        <section class="steps step-4" role="group" aria-label="App Languages">
          <div class="steps-inner">
            <div class="main-heading">
              {{content?.languageOptions?.title}}
            </div>

            <div id="step4">
              <div class="lightSpeedIn row" [formGroup]="step4Form">
                @for (option of languageOptions; track option.value) {
                  <div class="col-md-4">
                    <div class="job-single">
                      <input
                        type="radio"
                        formControlName="languagesSupported"
                        [value]="option.value"
                        [id]="option.value"
                        [attr.aria-invalid]="step4Form.get('languagesSupported')?.invalid && step4Form.get('languagesSupported')?.touched">
                      <label [for]="option.value">
                        <img [src]="option.imgSrc" [alt]="option.altText">
                        {{ option.label }}
                      </label>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div class="next-prev pop">
              <button
                type="button"
                class="prev"
                (click)="goToPreviousStep()"
                [disabled]="formSubmitted()"
                aria-label="Go to previous step">
                @if(backButton){
                  <img src="{{backButton}}" alt="Back">
                  }
              </button>
              <button
                type="button"
                class="next"
                (click)="goToNextStep()"
                [disabled]="step4Form.invalid || formSubmitted()"
                aria-label="Go to next step">
                {{nextButtonText}}<i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </section>
      }

      <!-- Step 5 -->
      @if (currentStep() === 5) {
        <section class="steps step-5" role="group" aria-label="Design Requirement">
          <div class="steps-inner">
            <div class="main-heading">
              {{content?.designOptions?.title}}
            </div>

            <div id="step5">
              <div class="lightSpeedIn row" [formGroup]="step5Form">
                @for (option of designOptions; track option.value) {
                  <div class="col-md-6">
                    <div class="job-single">
                      <input
                        type="radio"
                        formControlName="designRequirement"
                        [value]="option.value"
                        [id]="option.value"
                        [attr.aria-invalid]="step5Form.get('designRequirement')?.invalid && step5Form.get('designRequirement')?.touched">
                      <label [for]="option.value">
                        @if(option.imgSrc && option.altText){
                        <img [src]="option.imgSrc" [alt]="option.altText">
                        }
                        {{ option.label }}
                      </label>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div class="next-prev pop">
              <button
                type="button"
                class="prev"
                (click)="goToPreviousStep()"
                [disabled]="formSubmitted()"
                aria-label="Go to previous step">
                @if(backButton){
                  <img src="{{backButton}}" alt="Back">
                  }
              </button>
              <button
                type="button"
                class="next"
                (click)="goToNextStep()"
                [disabled]="step5Form.invalid || formSubmitted()"
                aria-label="Go to next step">
                {{nextButtonText}}<i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </section>
      }

      <!-- Step 6 -->
      @if (currentStep() === 6) {
        <section class="steps step-6" role="group" aria-label="App Monetization">
          <div class="steps-inner">
            <div class="main-heading">
              {{content?.monetizationOptions?.title}}
            </div>

            <div id="step6">
              <div class="lightSpeedIn row" [formGroup]="step6Form">
                @for (option of monetizationOptions; track option.value) {
                  <div class="col-md-6">
                    <div class="job-single">
                      <input
                        type="radio"
                        formControlName="appMonetization"
                        [value]="option.value"
                        [id]="option.value"
                        [attr.aria-invalid]="step6Form.get('appMonetization')?.invalid && step6Form.get('appMonetization')?.touched">
                      <label [for]="option.value">
                        @if(option.imgSrc && option.altText){
                          <img [src]="option.imgSrc" [alt]="option.altText">
                          }
                        {{ option.label }}
                      </label>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div class="next-prev pop">
              <button
                type="button"
                class="prev"
                (click)="goToPreviousStep()"
                [disabled]="formSubmitted()"
                aria-label="Go to previous step">
                @if(backButton){
                  <img src="{{backButton}}" alt="Back">
                  }
              </button>
              <button
                type="button"
                class="next"
                (click)="goToNextStep()"
                [disabled]="step6Form.invalid || formSubmitted()"
                aria-label="Go to next step">
                {{nextButtonText}}<i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </section>
      }

      <!-- Step 7 -->
      @if (currentStep() === 7) {
        <section class="steps step-7" role="group" aria-label="Development Start">
          <div class="steps-inner">
            <div class="main-heading">
              {{content?.startOptions?.title}}
            </div>

            <div id="step7">
              <div class="lightSpeedIn row" [formGroup]="step7Form">
                @for (option of startOptions; track option.value) {
                  <div class="col-md-4">
                    <div class="job-single">
                      <input
                        type="radio"
                        formControlName="developmentStart"
                        [value]="option.value"
                        [id]="option.value"
                        [attr.aria-invalid]="step7Form.get('developmentStart')?.invalid && step7Form.get('developmentStart')?.touched">
                      <label [for]="option.value">
                        @if(option.imgSrc && option.altText){
                          <img [src]="option.imgSrc" [alt]="option.altText">
                          }
                        {{ option.label }}
                      </label>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div class="next-prev pop">
              <button
                type="button"
                class="prev"
                (click)="goToPreviousStep()"
                [disabled]="formSubmitted()"
                aria-label="Go to previous step">
                @if(backButton){
                  <img src="{{backButton}}" alt="Back">
                  }
              </button>
              <button
                type="button"
                class="next"
                (click)="goToNextStep()"
                [disabled]="step7Form.invalid || formSubmitted()"
                aria-label="Go to next step">
                {{nextButtonText}}<i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </section>
      }

      
      @if (currentStep() === 8) {
        <section class="steps step-8" role="group" aria-label="Contact Information">
          <div class="steps-inner">
            <div class="main-heading">
              {{content?.contactForm?.title}}
            </div>
        
            <div id="step8" class="lightSpeedIn scroll-form" [formGroup]="step8Form">
              <div class="block">
                <div class="text_input" [class.invalid]="step8Form.get('name')?.invalid && step8Form.get('name')?.touched">
                  <input
                    type="text"
                    formControlName="name"
                    placeholder="{{content?.contactForm?.name}}"
                    [attr.aria-invalid]="step8Form.get('name')?.invalid && step8Form.get('name')?.touched"
                    aria-label="Full Name">
                  @if (getControlError(step8Form, 'name')) {
                    <div class="error-message" role="alert">
                    {{ getControlError(step8Form, 'name') }}
                  </div>
                  }
                </div>
        
                <div class="text_input" [class.invalid]="step8Form.get('email')?.invalid && step8Form.get('email')?.touched">
                  <input
                    type="email"
                    formControlName="email"
                    placeholder="{{content?.contactForm?.email}}"
                    [attr.aria-invalid]="step8Form.get('email')?.invalid && step8Form.get('email')?.touched"
                    aria-label="Email Address">
                  @if (getControlError(step8Form, 'email')) {
                    <div class="error-message" role="alert">
                    {{ getControlError(step8Form, 'email') }}
                  </div>
                  }
                </div>
        
                <div class="text_input" [class.invalid]="step8Form.get('company')?.invalid && step8Form.get('company')?.touched">
                  <input
                    type="text"
                    formControlName="company"
                    placeholder="{{content?.contactForm?.company}}"
                    [attr.aria-invalid]="step8Form.get('company')?.invalid && step8Form.get('company')?.touched"
                    aria-label="Company">
                  @if (getControlError(step8Form, 'company')) {
                    <div class="error-message" role="alert">
                    {{ getControlError(step8Form, 'company') }}
                  </div>
                  }
                </div>
        
                <div class="text_input message">
                  <input
                    type="text"
                    formControlName="message"
                    placeholder="{{content?.contactForm?.message}}"
                    aria-label="Message">
                </div>
              </div>
            </div>
        
            <div class="next-prev pop">
              <button
                type="submit"
                class="submit"
                [disabled]="step8Form.invalid || formSubmitted()"
                aria-label="Submit application">
                <ng-container *ngIf="!formSubmitted(); else submitting">
                  {{submitButtonText}}<i class="fa-solid fa-paper-plane"></i>
                </ng-container>
                <ng-template #submitting>
                  <i class="fa-solid fa-spinner fa-spin"></i> Submitting...
                </ng-template>
              </button>
            </div>
          </div>
        </section>
      }
    </div>
  </form>
</div>
