<div class="row">
  <div class="col-6">
<span class="title">
  {{title}}
</span>
<p class="text">{{text}}</p>
<div class="ticks">
  <span class="text-tick">
    <img class="tick" src="{{ tick }}" alt="tick">
  <p>{{tick1}}</p></span><br>
  <span class="text-tick">
    <img class="tick" src="{{ tick }}" alt="tick">
    <p>{{tick2}}</p></span><br>
  <span class="text-tick">
    <img class="tick" src="{{ tick }}" alt="tick">
  <p>{{tick3}}</p></span>
  </div>
  </div>
  <div class="col-6">
    @if(image){
    <img class="image" #imageElement [@growFadeIn] src="{{image}}" alt="image">
    }
  </div>
</div>
