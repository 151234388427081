import { Component, OnDestroy, inject } from '@angular/core';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { CmsService } from '../../services/cms.service';
import { environment } from '../../../environments/environment.prod';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-references',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnDestroy {
  title: string = '';
  protected environment = environment;
  references: any[] = [];
  private lightbox: PhotoSwipeLightbox | undefined;

  cms = inject(CmsService);

  ngOnInit(): void {
    this.getContent();
  }

  ngOnDestroy() {
    if (this.lightbox) {
      this.lightbox.destroy();
    }
  }

  private initPhotoSwipe() {
    if (this.lightbox) {
      this.lightbox.destroy();
    }

    this.lightbox = new PhotoSwipeLightbox({
      gallery: '#photoSwipeGallery',
      children: 'a',
      pswpModule: () => import('photoswipe'),
      showHideAnimationType: 'fade',
      loop: true,
      closeOnVerticalDrag: true,
      clickToCloseNonZoomable: true,
      zoom: true,
      paddingFn: (viewportSize) => {
        return {
          top: 30,
          bottom: 30,
          left: 70,
          right: 70
        };
      }
    });
    this.lightbox.init();
  }

  getContent() {
    let component = 'references';
    let fields = ['references'];
    let populateField = 'referenceImg';
    let locale = 'en';

    this.cms.getNestedContent(component, fields, populateField, locale).subscribe({
      next: (res: any) => {
        const references = res.data.references;
        this.title = references.title;
        this.references = references.references;

        setTimeout(() => {
          this.initPhotoSwipe();
        }, 0);
      },
      error: (err) => {
        console.error('Error loading references:', err);
      }
    });
  }
}
