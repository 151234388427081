import { Component, inject } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';

interface Card{
  id : number;
  title: string;
  text: string;
  image: string;
}

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})


export class CardComponent {
  cards: Card[] = [];

  cms = inject(CmsService);

  ngOnInit(): void {
    this.getCards();
  }

  getCards() {
    const locale = 'en';

    this.cms.getCards(locale).subscribe({
      next: (response: any) => {
        this.cards = response.data.map((item: any) => ({
          id: item.id,
          title: item.title,
          text: item.text,
          image: environment.CMS_URL + item.image.url
        }));
      },
      error: (error) => {
        console.error('Error fetching card content:', error);
      }
    });
  }
}
