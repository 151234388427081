<div class="title">
  <h1>{{ title }}</h1>
</div>

<div class="pswp-gallery" id="photoSwipeGallery">
  @for(reference of references; track reference){
  <a href="{{environment.CMS_URL}}{{reference.referenceImg.formats.large?.url || reference.referenceImg.formats.medium?.url || reference.referenceImg.url}}" 
    data-pswp-width="1600" 
    data-pswp-height="900" 
    target="_blank"
  >
    <img 
      src="{{environment.CMS_URL}}{{reference.referenceImg.formats.small?.url || reference.referenceImg.formats.thumbnail?.url}}" 
      alt="{{reference.referenceAlt}}" 
    />
  </a>
}
</div>
