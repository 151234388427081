import { Component, inject } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-image-text-button',
  standalone: true,
  imports: [],
  templateUrl: './image-text-button.component.html',
  styleUrl: './image-text-button.component.scss'
})
export class ImageTextButtonComponent {

  heading : string = '';
  title : string = '';
  text : string = '';
  tick1 : string = '';
  tick2 : string = '';
  tick3 : string = '';
  buttonText : string = '';
  buttonRedirect : string = '';
  image : string = '';
  tick : string = '';
  cmsUrl = environment.CMS_URL;

  cms = inject(CmsService);

  ngOnInit(): void {
  this.getContent();
  }

  getContent(){
    let component = 'whyChooseUs';
    let fields = ['image','tick'];
    let locale = 'en';

    this.cms.getContent(component, fields, locale).subscribe((res:any) => {
      const content = res.data.whyChooseUs;
      this.heading = content.heading;
      this.title = content.title;
      this.text = content.text;
      this.tick1 = content.tick1;
      this.tick2 = content.tick2;
      this.tick3 = content.tick3;
      this.buttonText = content.buttonText;
      this.image = this.cmsUrl + content.image.url;
      this.tick = this.cmsUrl + content.tick.url;
    })
  }

  scrollToIntroduction() {
    const introductionElement = document.querySelector('app-introduction');
    if (introductionElement) {
      introductionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
