import { Component, inject } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { IntroductionComponent } from '../../components/introduction/introduction.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { TextImageComponent } from '../../components/text-image/text-image.component';
import { ServicesComponent } from '../../components/services/services.component';
import { ReferencesComponent } from '../../components/references/references.component';
import { ProcessComponent } from '../../components/process/process.component';
import { ImageTextButtonComponent } from '../../components/image-text-button/image-text-button.component';
import { ScrollToTopComponent } from '../../components/scroll-to-top/scroll-to-top.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [HeaderComponent,IntroductionComponent,TextImageComponent,ServicesComponent,ReferencesComponent,ProcessComponent,ImageTextButtonComponent,ScrollToTopComponent,FooterComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
}
