import { Component, inject } from '@angular/core';
import { CardComponent } from '../card/card.component';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [CardComponent],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent {

  cms = inject(CmsService);
  title : string = '';



  ngOnInit(): void {
    this.getContent();
  }

  getContent(){
    let locale = 'en';
    let fields = [''];
    let component = 'services'

    this.cms.getContent(component, fields, locale).subscribe((res:any) => {
      this.title = res.data.services.title;
    })
  }


}


