import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { slideInRight } from '../../animations/animations';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  standalone:true,
  imports:[CommonModule],
  animations:[slideInRight]
})
export class ScrollToTopComponent {
  isVisible: boolean = false;

  
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isVisible = window.scrollY > 500;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
