import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { environment } from '../../../environments/environment';
import { growFadeIn } from '../../animations/animations';
import { CommonModule } from '@angular/common';

interface Transform{
  title: string;
  text: string;
  image:string;
  tick1: string;
  tick2: string;
  tick3: string ;
}

@Component({
  selector: 'app-text-image',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-image.component.html',
  styleUrl: './text-image.component.scss',
  animations:[growFadeIn]
})
export class TextImageComponent {
text : string = '';
title : string = '';
image: string = '';
tick : string = '';
tick1: string = '';
tick2: string = '';
tick3: string = '';
isInView: boolean = false;

cms= inject(CmsService);
@ViewChild('imageElement', { static: false }) imageElement!: ElementRef;

ngOnInit(): void {
  this.getContent();
}


getContent() {
  let component = 'transform'
  let locale = 'en';
  let fields = ['image', 'tick'];

  this.cms.getContent(component, fields, locale).subscribe({
    next: (response: any) => {
      const transform: Transform = response.data.transform;   
      this.title = transform.title;
      this.text = transform.text;
      this.tick1 = transform.tick1;
      this.tick2 = transform.tick2;
      this.tick3 = transform.tick3;
      this.image = environment.CMS_URL + response.data.transform.image.url;
      this.tick = environment.CMS_URL + response.data.transform.tick.url
    },
    error: (error) => {
      console.error('Error fetching introduction content:', error);
    }
  });
}

observeImage() {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isInView = true; 
          observer.disconnect(); 
        }
      });
    }, 
    { threshold: 1 } 
  );

  if (this.imageElement) {
    observer.observe(this.imageElement.nativeElement);
  }
}
}
